import { useEffect, useState } from "react"
import { API } from "./api"
import { LoadingIcon } from "./Icons"
import { Devices } from "./models"

const removeIdFromDescription = (id: string, description: string): string => {
  return description.replace(` - ${id}`, '')
}

const DeviceRow = ({ description, running, onClick }: { description: string, running: boolean, onClick: () => void }) => (
  <div
    className="flex flex-row p-4 justify-start margin-auto py-4 hover:bg-slate-200 active:bg-slate-300 select-none items-center dark:hover:bg-slate-800 dark:active:bg-slate-900"
    onClick={onClick}
  >
    <div className={`w-3 h-3 ${running ? 'bg-green-500' : 'bg-red-500'} rounded-full mr-4`}></div>
    <p>{description}</p>
    <div className="grow"></div>
    <p className="ml-20">{running ? 'Running' : 'Stopped'}</p>
  </div>
)

const DevicePicker = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<Devices>()
  const [error, setError] = useState<string>('')

  useEffect(() => {
    (async () => {
      try {
        const data = await API.device.listDevices()
        setData(data)
      } catch (err) {
        setError(`${err}`)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  const onSelectDevice = async (instanceId?: string, modelId?: string) => {
    setIsLoading(true)
    await API.device.selectDevice(instanceId, modelId)
  }

  if (isLoading) return (
    <div className="w-full h-full flex items-center justify-center">
      <LoadingIcon />
    </div>
  )

  if (!!error) return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="p-4 bg-red-100 border-red-200 border-2">
        <p>{error}</p>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col bg-slate-100 w-1/2 m-auto mt-20 border-2 border-slate-200 dark:bg-slate-750 dark:border-slate-600 dark:text-white">
      <div className="p-4 border-b-2 dark:border-b-slate-600">
        <p className="font-bold">Connect to a device</p>
      </div>

      {[...data?.connected || [], ...data?.availableForLaunch || []].length === 0 ? (
        <div className="p-4">
          <p className="font-bold mb-4">No devices found.</p>
          <p>Please refer to the official documentation regarding <a className="underline" href="https://developer.apple.com/documentation/xcode/running-your-app-in-simulator-or-on-a-device" target="_blank" rel="noreferrer">how to create an iOS Simulator</a> and <a className="underline" href="https://developer.android.com/studio/run/managing-avds" target="blank">how to create an Android emulator</a>.</p>
        </div>
      ) : (
        <>
          {data?.connected.map(device => (
            <DeviceRow key={device.instanceId} description={removeIdFromDescription(device.instanceId, device.description)} running={true} onClick={() => onSelectDevice(device.instanceId, undefined)} />
          ))}

          <div className="border-b dark:border-b-slate-600" />

          {data?.availableForLaunch.map(device => (
            <DeviceRow key={device.modelId}  description={removeIdFromDescription(device.modelId, device.description)} running={false} onClick={() => onSelectDevice(undefined, device.modelId)} />
          ))}
        </>
      )}
    </div>
  )
}


export default DevicePicker
