import { LOCAL_STORAGE_KEYS } from '../constants'

const PROBE_DELAY = 250;

const isReachable = async (host: string): Promise<boolean> => {
  const res = await fetch(host)
  return Promise.resolve(res.ok)
}

export const discoverHost = async () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.HOST)

  return new Promise((resolve, reject) => {
    const probe = async (port: number) => {
      if (port > 11000) return reject("Could not find API host")

      try {
        const potentialHost = `http://localhost:${port}`
        const hostIsReachable = await isReachable(potentialHost)
        if (hostIsReachable) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.HOST, potentialHost)
          console.log(`${potentialHost} is reachable!`)
          return resolve(potentialHost)
        }
      } catch (err) {}

      setTimeout(() => probe(port + 1), PROBE_DELAY)
    }

    probe(9999)
  })
}

export const getHost = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOST) ?? ''
}
