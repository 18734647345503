import React, { ReactNode, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom"
import { API } from './api';
import DeprecatedInspectPage from './DeprecatedInspectPage';
import DevicePicker from './DevicePicker';
import Header from './Header';
import { LoadingIcon } from './Icons';
import InteractPage from './InteractPage';
import MockPage from './MockPage';
import { discoverHost, getHost } from './util/host';

const Loading = () => (
  <div className="w-full h-full flex items-center justify-center">
    <LoadingIcon />
  </div>
)

const EnsureSelectedDevice = ({ children }: { children: ReactNode }) => {
  const {data: selectedDevice, isLoading} = API.device.useSelectedDevice({ refreshInterval: 1000 })

  if (isLoading) return <Loading />

  if (!selectedDevice) return (
    <DevicePicker />
  )

  return (
    <>
      {children}
    </>
  );
}

const App = () => {
  const [isLoading, setIsLoading] = useState(true)
  const isStorybook = process.env.STORYBOOK === 'true'

  useEffect(() => {
    (async () => {
      if (isStorybook) {
        setIsLoading(false)
        return
      }

      try {
        await discoverHost()
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  if (isLoading) return <Loading />

  if (!isLoading && !getHost() && !isStorybook) return (
    <div className="w-full h-full flex items-center justify-center">
      <p className="text-lg text-red-600">Could not find host. Make sure `maestro studio` is running locally.</p>
    </div>
  )

  return (
    <div className='flex flex-col h-screen overflow-hidden dark:bg-slate-800'>
      <Header />
      <div className="overflow-hidden h-full">
        <EnsureSelectedDevice>
          <Routes>
            <Route path="inspect" element={ <DeprecatedInspectPage/> } />
            <Route path="mock" element={ <MockPage/> } />
            <Route path="interact" element={ <InteractPage/> } />
            <Route path="*" element={<Navigate to="/interact" replace />} />
          </Routes>
        </EnsureSelectedDevice>
      </div>
    </div>
  )
}

export default App;
