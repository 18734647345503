import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';

if (window.electronAPI) {
  window.electronAPI.onStudioApiStarted(() => window.location.reload())
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
);
